import { subDays } from "date-fns";
import { mock } from "../lib/axios";


const now = new Date();

mock.onGet("/api/blog/posts").reply(() => {
  const posts = [
    {
      id: "24b76cac9a128cd949747080",
      author: {
        avatar: "/static/mock-images/avatars/caroline_forsey.png",
        name: "Caroline Forsey",
      },
      category: "Marketing",
      cover: "/static/mock-images/covers/sponsored-instagram.webp",
      publishedAt: subDays(now, 3).getTime(),
      readTime: "10 min",
      shortDescription:
        "It's no surprise you want to become a paid Instagram influencer -- heck, the average price for a sponsored Instagram post is $300, and if you become more successful, like yogi Rachel Brathen, you could be making $25,000 per post.",
      title:
        "How to Get Sponsored on Instagram (Even if You Currently Have 0 Followers) ",
    },
    {
      id: "a9c19d0caf2ca91020aacd1f",
      author: {
        avatar: "/static/mock-images/avatars/lindsey.jpeg",
        name: "Lindsey Valdez",
      },
      category: "Brand partnership",
      cover: "/static/mock-images/covers/K1600_how-to-get-brand-deals.JPG",
      publishedAt: subDays(now, 17).getTime(),
      readTime: "6 min",
      shortDescription:
        "Brand collaboration is one way a content creator can monetize their efforts, but it’s a competitive space..",
      title: "6 Common Mistakes That Jeopardize Your Brand Partnership",
    },
    {
      id: "44df90cbf89963b8aa625c7d",
      author: {
        avatar: "/static/mock-images/avatars/lindsey.jpeg",
        name: "Lindsey Valdez",
      },
      category: "Pitching",
      cover: "/static/mock-images/covers/cover_1.jpg",
      publishedAt: subDays(now, 31).getTime(),
      readTime: "7 min",
      shortDescription:
        "Successful bloggers and influencers know how to pitch themselves. What’s more, understanding the fundamentals of pitching means you are more likely to win collaborations with the brands that you like the most. This helps your overall image to remain authentic and you keep the trust of your hard-earned fans. The reason this is so important is trust.        ",
      title: "How to pitch yourself as an influencer to brands",
    },
  ];

  return [200, { posts }];
});

mock.onGet("/api/blog/posts/24b76cac9a128cd949747080").reply(() => {
  const post = {
    id: "24b76cac9a128cd949747080",
    author: {
      avatar: "/static/mock-images/avatars/caroline_forsey.png",
      name: "Caroline Forsey",
    },
    category: "Marketing",
    content: `
But the idea of getting your posts sponsored might seem laughable to you. You're not posting pictures skydiving in Australia -- you're posting pictures of your brunch. However, you could be more marketable than you think.
Click here to access a month's worth of Instagram tips & free templates.
Instagram has become an insanely popular channel for brands to promote their products. In fact, Influencer Central found consumers consider Instagram to be the sixth most effective at influencing their purchasing decisions.

Instagram's popularity might make you feel the platform is already too crowded for you to stand out. But here's the thing -- brands are quickly realizing the power of normal people to promote their products. Micro-influencers, or people with a small number of followers compared to the big players, see the most engagement out of their audience.

Think of it this way: I'm going to trust my best friend's advice over Kim Kardashian's when I'm purchasing a product. I trust my best friend, we share similar interests, and I know she's genuine with her advice (no offense, Kim … ).

It's the same concept for micro-influencers -- with the right strategy, your audience will begin to see you as one of their real friends. The more they trust your advice when seeking out purchasing decisions, the more likely you are to get sponsored.

Here, we're going to show you everything you need to do to get sponsored on Instagram, even if you currently have zero followers. Keep reading to get started or click the links below to jump to a specific section of this article.


# Define your brand.
You'll see the best engagement if you're able to define your niche. Do you want to post food and health related content, or focus on fashion? Whatever the case, it's important to establish your brand.

Besides the type of content you post, branding has a lot to do with your overall aesthetic. How do you want to style your posts? What's your messaging? To further solidify your brand, you might want to consider creating a cohesive feed theme (use these feeds for inspiration).

Specificity is key. A good influencer's posts are distinguishable and unique -- when a user is flipping through her feed, she'll be able to pause and recognize every time she sees a post from that influencer. As she continues to see similar content, she'll grow to trust that brand as an expert in the field. If the influencer suddenly and randomly changed course, the user might not understand or trust the content anymore.

Additionally, you might want to connect your Instagram brand with an online presence. Creating a website with similar aesthetic and messaging is a good way to do this -- the more you unify your social media accounts, the easier it will be for brands to distinguish how you can help them.

# Know your audience.
Knowing your audience is critical for convincing a brand to work with you. It's mutually beneficial for you, as well -- if you understand your audience, you're able to correctly identify which brands will see the most success from using you as their sponsor.

Start by gathering the basics -- what is the gender, age, and geographical location of your core demographic? Which of your posts do they like the best? What times of day do they respond best to content, and what can you infer from this?

The demographic information you gather will help you pitch partnerships with brands. Brands want to know who they can reach if they work with you. Explaining "You'll be reaching thirty-something, working women, primarily from New York, who often use Instagram first thing in the morning and prefer fitness content" is certainly more powerful than saying, "You'll be reaching women."

# Post consistently.
CoSchedule gathered research from 14 studies to identity how often you should post on social media sites. For Instagram, they found you should post a minimum of once a day, but can post upwards of three times a day.

CoSchedule also found 8:00 AM to 9:00 AM, and 2:00 AM, are the best times to post.

To grow your following, it's critical you post at least once a day. Instagram's algorithm favors new and fresh content, and you don't want your audience to unfollow you or forget about you from lack of consistency.

However, you'll need to figure out what works best for you and your audience. Perhaps your audience feels bombarded when you post three times a day, or maybe they prefer it. Maybe your audience engages most with your posts at noon. It will take some trial and error, as well as Instagram metrics tools, to figure this out.


# Use hashtags and geotags.
Hashtags make your content more discoverable, so they're necessary for growing your following. You can use up to 30 hashtags per post, but TrackMaven found nine to be the optimal number for boosting engagement.

You'll want to use hashtags as relevant to your content as possible. You'll also need to check to make sure the hashtags you use aren't broken or banned (take a look at this list of banned hashtags if you're unsure).

It's critical you choose hashtags that aren't too broad. #Healthyliving, for instance, has over 20,000,000 posts, while #healthylivingtips only has 13,000. The less competition, the easier it will be for your content to get discovered.

When you peruse a hashtag's page, you can also get a deeper sense of what types of content your post will be up against. #Healthylivingtips might typically feature posts with food recipes, while your post is about cycling -- this could defer you from using that hashtag.

Geotags are equally important, but for a different reason. Geotags can help people find you if they're interested in a certain location. This helps you gain more followers, and it also helps you appeal to brands that are interested in reaching a certain demographic. For instance, maybe a boutique sees you often post fashion tips from the California area, and they're looking to appeal to people in that region -- it's a win, win.

# Tag brands in your posts.
Okay, now you're officially ready to begin reaching out to brands. You've defined your brand and audience and have created some quality, authentic posts. Now, you should have a pretty good idea what types of businesses would benefit from a partnership with you.

It's important to start small. If you're interested in skincare, don't go straight for Estee Lauder -- instead, try tagging small skincare start-ups you've seen across Instagram already.

Let's take a look at an example -- @Tzibirita, a travel influencer, posted this image of herself wearing a Paul Hewitt watch. The image is high-quality and fits with her brand, and she tags @paul_hewitt in her description. Even if you're not paid by Paul Hewitt, you can still post the same type of content and tag their brand in the post. Ideally, it will at least put you on their radar.


Begin with small brands and tag them in your descriptions. Engage with your audience by responding to comments like "Where can I get one?" or "How much?" and the brand will soon see you've proven yourself a suitable sales partner.

# Include contact information in your bio.
Consider your bio a chance to signal to brands your interest in becoming an influencer. Include an email or website so they can reach you, and include a press kit if possible.

For instance, @tzibirita doesn't waste her bio space. She includes her email and website, and even adds a title -- "content creator". Brands will have no doubt she's open to doing business with them.

Furthermore, you should use a website or blog as your chance to expand on your brand and demonstrate your versatility. Consider adding a Press Page to your website, so brands can take a look at your services. Once you begin sponsoring brands, you can add them to this page so brands can see you have influencer experience.

# Pitch paid sponsorships.
There's nothing wrong with reaching out to brands and offering your services. With the right pitch, you might be able to land some gigs without waiting for brands to find you.

Look for brands that clearly invest time and money into their Instagram presence. You might start by researching what similar influencers in your industry already sponsor. Remember, it's okay to start small. Working with smaller brands will allow you to build a portfolio.

Once you've curated a list of brands that might want to partner with you, send them an email. In your pitch, clearly and briefly outline who you are, what you do, and any achievements you have in the field that make you an expert. Then, explain why you're a good fit for the brand, and include data such as follower count and average engagement rate.

Alternatively, you might consider sending a brand a DM straight from Instagram. It's certainly more relevant to the job you're vying for, but it might get lost if a brand get hundreds of DMs a day.

# Know your worth.
Make sure you know how much you're going to charge when brands reach out to you. The industry standard is $10 for 1,000 followers, but it could also vary depending on how many likes you get per post. Additionally, as you grow, you'll be able to charge more.

While you'll want to have a minimum set, you can negotiate to encourage brands to pay more. Perhaps for $300, you'll throw in five Instagram Story posts, and a link in your bio to their website for 24 hours. You can use other Instagram features to sweeten the deal.

Once you have your pricing structure nailed down, you'll need to know how to sponsor a post on behalf of the brand you're working with. Now, keep in mind there are two different kinds of "sponsored" posts: those for which brands pay Instagram, and those for which brands pay another user.

Confused? Here's what I mean:

What's a Sponsored Instagram Post?
A sponsored post on Instagram is paid for by the poster to reach a wider audience. There are two main types of sponsorships: In one, a brand creates a post and pays Instagram for access to a custom audience. In the other, a brand sponsors another Instagram user -- often called an "Influencer" -- who creates a post that features the brand in some way.

Here's more detail on each type of sponsored post:

## Promoted Posts & Ads
Just like Twitter, LinkedIn, and Facebook, Instagram comes with a native ad management platform. Advertisers can use this tool to customize a target audience -- using attributes like age, sex, location, and interests -- and invest a specific amount of money to getting their post in front of Instagrammers who identify with this audience.

The thing to remember here is that the advertiser is making and publishing the post. They're paying Instagram for the audience they want access to, but the post is theirs to create.

## Paid Sponsorships
Paid sponsorships take place between a brand and another Instagram user. Typically, this user has a personal brand and attracts an audience of his or her own. This user is often called an "influencer."

This person can then use the steps explained earlier in this article to find and work with brands that appeal to a similar audience. When they find a brand who wants to sponsor them, they can charge this client a certain amount to create a post that features their product or service. Think of it like social media product placement; just like a business might pay a TV show to have their brand of soda on the countertop in the series finale, they can also pay a person on Instagram to hold that same soda in a picture on their Instagram feed.

Clearly there are more creative sponsorship ideas you can come up with -- I decided to go with a cliché ...

Influencers are similar to Instagram's ad manager in the sense that they both draw an audience that brands might not otherwise have access to. However, the differences in this type of sponsorship are that the brand is paying the influencer -- rather than Instagram -- for access to their audience, and the influencer -- rather than the advertiser -- is the one creating and publishing the post.

There are numerous influencers for each industry. Here's a big list of today's known influencers and the types of audiences they attract.

# Using #ad and #spon Hashtags
In the past couple years, brands have come under fire for hiring influencers but not making it clear to the audience that these influencers were getting paid.

Department store Lord & Taylor, for instance, settled charges with the FTC in 2016 after paying 50 influencers to wear a dress in their posts without hashtagging #sponsorship or #ad.

Influencers are supposed to hashtag #ad or #sponsored in posts they're being paid for, but these tags make some brands uncomfortable because it makes the post appear inauthentic.

In 2017, Instagram released a paid partnership feature to combat this issue -- if you tag a brand in a post and the brand confirms the relationship, the ad will be marked at the top with a "paid partnership" label. This also helps the brand gather data regarding how well the campaign is performing.

It's critical your followers know if you're getting paid to promote a product. Ethics aside, it could destroy your account's credibility if you're caught, and lose everything you've worked hard to build -- namely, an authentic, trusting community.

If you truly don't want to post #ad or #spon, there are some ways around it -- for instance, Airbnb created the hashtag #Airbnb_partner, to signal a paid partnership without using the word "ad".

When in doubt, adhere to Instagram's policies. You can read Instagram's branded content policies in full here.

Ultimately, getting sponsored on Instagram isn't easy -- it takes time, effort, and perseverance. But if you work hard to differentiate yourself in the industry, and connect on a personal level with your followers, it can be extraordinarily rewarding.


    `,
    cover: "/static/mock-images/covers/sponsored-instagram.webp",
    publishedAt: subDays(now, 3).getTime(),
    readTime: "10 min",
    shortDescription:
      "It's no surprise you want to become a paid Instagram influencer -- heck, the average price for a sponsored Instagram post is $300, and if you become more successful, like yogi Rachel Brathen, you could be making $25,000 per post.",
    title:
      "How to Get Sponsored on Instagram (Even if You Currently Have 0 Followers)",
  };

  return [200, { post }];
});

mock.onGet("/api/blog/posts/a9c19d0caf2ca91020aacd1f").reply(() => {
  const post = {
    id: "a9c19d0caf2ca91020aacd1f",
    author: {
      avatar: "/static/mock-images/avatars/lindsey.jpeg",
      name: "Lindsey Valdez",
    },
    category: "Brand partnership",
    content: `
First, the brand will shares details on their budget, marketing campaign, and type of influencers they want to work with. Next, we’ll filter the network for social following requirements, blog traffic, and the quality of both. Finally, we submit our choices back to the brand. They’ll either approve or nix our recommendations, and if it’s the latter, we go another round.

Since we often receive the same feedback, here are the most common mistakes you’ll want to avoid if you’re banking on paid brand campaigns.

# Poor Quality Content
Quality will continue to be the most important factor for brands while recruiting. A poor quality blog or Instagram account won’t reflect positively on the advertiser and with an abundance of beautiful accounts to choose from, there is little chance they would take the risk. Our internal teams have a keen eye for high-quality content–here’s what catches our eye.

When we look at a partner’s website, we want to see a consistent stream of published content. If there is a ton around the holidays and then crickets for months following, that’s an indicator that the blog is not maintained regularly. It’s also important that your articles are long-form and cover topics thoroughly. Google doesn’t reward thin content or content that doesn’t help the reader. Make sure your posts are accurate and fact-based.

Brand safety is a factor for most large advertisers. They spend major dollars to prevent their brand from appearing next to certain terms and topics. In order to attract the more lucrative, mainstream brands, avoid things like using expletives, promoting violence, hate speech, and other content that violates internet guidelines.

Social accounts like Instagram and Facebook should feel authentic and personal. When we look at an account covered in memes, news headlines, and inspirational quotes, we often move on to others. Brands are looking to sell products and gain new customers. As it pertains to influencer marketing, this works best when their accounts have a more humanized feel and followers can relate to their lives.

On that note, it’s important to prominently display information about yourself on your blog. Users are savvier than ever and want to know where their information is coming from. Spend time creating an interesting About Page. Talk about your mission and your story.

Your social media links should be listed and hyperlinked from the homepage. We’re shocked to still see so many homepages without links (or with broken links!) to social accounts. For brands, it’s the combined reach of your blog and socials that they’re most interested in.  You can bet they’ll be looking for those handles. We recommend having links to your social accounts listed at the top of your homepage versus in the footer or sidebar.

# Too Much Sponsored Content
You didn’t know that was even a thing, did you? Brands aren’t interested in paying for their products to be posted next to ten other sponsored posts. When we check a blog’s homepage or an Instagram feed and struggle to find organic posts, we hesitate to refer those influencers to a brand because it’s almost sure to be rejected. Even when the content itself is excellent, sometimes we altogether pass.

Having just come out of Q4, you may have published even more branded content than normal. But now that it’s passed, you should resume more organic (non-sponsored) posts. And I mean, much more. The same goes for your Instagram and other social posts.

Depending on how big an issue this is for your business, there are several options. Natural blog and Instagram posts appear in the ballpark of an 8:1 ratio and the very most. This means for every eight organic posts, one can go up that’s sponsored. With this in mind, the easiest thing to do is increase the number of times you post non-sponsored content to balance it out. There is a certain authenticity brands look for so don’t be afraid to let that show through your content, especially if it means providing you with additional content inspo. 

On the blog, it’s the homepage appearance that’s most significant. If you’re on WordPress, you can have a developer build custom functionality that allows you to curate your homepage. Otherwise, by default, as you publish new content, it automatically pins to the homepage. 


# A Neglected Website
Now more than ever, advertisers are finding value in a more holistic package approach. It’s rare that a campaign comes in requesting only Instagram posts or TikTok. The modern-day influencer campaign includes a combination of blog posts, static social posts, story-style video posts, and more. Build your brand to have a footprint in all of these areas.

The largest missed opportunities we see come from neglected blogs. There was an era when the number one goal of every influencer was to grow Instagram because that’s where the money was. But now, with oft-equal interest in sponsored blog posts, it’s time for the focus to shift or at least balance. Your blog should be viewed as your most important real estate. You own your blog in a way you do not own your social accounts.

# Mismatched Pitching
When you’re applying for influencer campaigns, read the application carefully and be honest with yourself as to whether you’re a good fit. Brands are paying more attention to the details within your content. They want to work with influencers who have a similar audience or mission because when people see sponsored content from someone they trust, they’ll be more aware of a product and more likely to buy it. Focus on building a niche audience and many times, this smaller, niche-specific audience is more valuable to advertisers than larger ones. And although we absolutely recognize the value to brands of the micro-influencer, many brands (especially when the campaign is on Instagram) still require social media followings of 20k+.

# Poor Reputation
Details like dates, post copy, and image specifications matter. If an influencer doesn’t fulfill the requirements of the campaign, they aren’t likely to continue to work with you on other campaigns. It’s in your best interest to commit to only what you can handle. Sometimes what starts off as a one-off campaign flight can be the beginning of a longer, deeper relationship with the brand.

# Not an Engaged Community
At the end of the day, your audience is the MVP when it comes to landing influencer work. Take care of them and they will take care of you. If you have millions of followers or page views a month, but can’t drive a sale, brands won’t want to work with you. Engage with your audience, communicate with them and ultimately, only shell out for products/brands you believe will resonate with them. Value the user above all else.
    `,
    cover: "/static/mock-images/covers/K1600_how-to-get-brand-deals.JPG",
    publishedAt: subDays(now, 17).getTime(),
    readTime: "10 min",
    shortDescription:
      "Brand collaboration is one way a content creator can monetize their efforts, but it’s a competitive space. Advertisers routinely sift through throngs of influencers and just a fraction are usually chosen for campaigns. Every week, we work to match advertisers with creators in the SHE Media Partner Network, and depending on the brand’s needs, this process can take several rounds to finalize.",
    title:
      "6 Common Mistakes That Jeopardize Your Brand Partnership",
  };

  return [200, { post }];
});

mock.onGet("/api/blog/posts/44df90cbf89963b8aa625c7d").reply(() => {
  const post = {
    id: "44df90cbf89963b8aa625c7d",
    author: {
      avatar: "/static/mock-images/avatars/lindsey.jpeg",
      name: "Lindsey Valdez",
    },
    category: "Pitching",
    content: `
As our 2018 influencer marketing survey uncovered, consumers are more likely to buy a product from someone based on trust – not the size of the following. The reason why this is so important brands favour influencers who inspire and influence and will want to work with you again if your campaign has been successful.

Here is everything you need for your killer pitch to the brands you love:

# Research the brand
You’ll get the best results when you really know the brand you’re hoping to work with. Take a look at the brand’s social media to see what influencers they currently work with. If you notice they only use influencers on their Instagram and never on Twitter, you’ll know that your pitch should be Instagram focussed, even if you do have a large Twitter following.

Make sure you do your due diligence on your chosen brand. Ask yourself the following questions:

- What social media channels are they active across?
- What influencer marketing do they have already?
- Who is their target market?
- What products are you interested in promoting the most?
- Is there an email of an actual person to contact
- Do they have a press kit you can see for inspiration?
- Know your follower demographics
- Knowing who your follower base is will help you to demonstrate your value. Your follower base is likely to vary from platform to platform. In general, brands will want to know things like, top audience locations, average age groups and gender split – all of which is automatically updated on your free ZINE media kit. Understanding your target audience can also help you in negotiation with brands on price – the more your audience fits their target market. The more valuable you are to them.

# Social-Demographics-instagram

  

Don’t just look at your own demographics though, take a holistic approach to look at the brands target demographic and the industry’s as well. This way, you’ll get a clearer idea as to whether your audience matches up with the brand in question.

# Work on your personal brand
Your personal brand is everything. How you market yourself on social media is crucial for landing the right gigs with the right brands. In our interview with Elena Sandor from Eat-Wear-Travel, she explains the importance of getting yourself out there.

  

“You have to put your face out there because it’s how you connect with PRs and brands. Not just you sitting behind a desk or laptop. It’s really important for people to actually see you and know you. That’s how I managed to secure some of my contacts.”

  

Make use of any industry event you can to really grow your network. You can then start to leverage connections you make, and connections of connections building up your network of brand, PR and agency contacts.

  

Your personal brand means having consistent messaging across all your social media channels. Potential brands want to see that your Twitter, Facebook, Instagram and Youtube are all aligned with a similar type of audience.

# How to introduce yourself
When you reach out to brands by email make sure you are reaching out to an actual person as opposed to a team address e.g. ‘marketing@brand.com’. It’s crucial here that you find the right specific person to contact as most people within the company probably has hundreds of emails coming to their inbox each day.

  

Then, in the email body – get straight to the point. Your email should explain who you are and how you can help the brand achieve its goals – not ‘what you want’.

  

You can then include a link to your ZINE media kit which automatically updates with your audience demographics, engagement rates, and sample content to strengthen your case. Don’t forget to make sure you have added previous collaborations and rate card price list too.

  

You’ll also want to include a call to action. This encourages the person you’re sending the email to, to complete a specific task. For example, if you want the brand to reply to you via email, ask them to. If you want them to check out your content, include a direct link.

# Influencer pitch templates
There’s no set way to begin pitching brands but if you can come up with creative ways to connect with them you stand a great chance. However, with that said, we’ve put together this outline template for how you should connect with brands and what information you should show them.

  

Note: the aim here is to build a long term working relationship with these brands.

  

When crafting your pitch email you’ll want to cover the following:

  

1. Who you are

2. What you’ve done in the past and what social channels you use frequently

3. Some examples of relevant recent posts they might be interested in

4. Why you want to work with this brand in particular

5. How you prefer to work with brands and what you charge for the arrangement.

Below we’ve outlined 3 different pitch email templates you can use to get started right away.

# Pitching a brand as a new influencer
Even if you’re a new influencer, or haven’t worked with a brand before, doesn’t mean you can’t start. When you have less brand partnership examples to share, it’s crucial you keep this pitch short, sweet and to the point.

  

Hey [THEIR NAME],

I’ve used [BRAND] for over three years now and [REASON WHY YOU LIKE IT]. I particularly love [GET SPECIFIC].

My podcast [SOCIAL CHANNELS] audience is made up of 90% [DEMOGRAPHIC STATISTICS] of males [THEIR TARGET DEMOGRAPHIC] and would also love your products.

My podcast [link to social channels] gets 10000000 listeners per month [statistic]. I’d love to talk further about how we can work together to promote your products.

Look forward to hearing back from you soon!

[YOUR NAME]

# Pitching a brand for a free stay
Hi [THEIR NAME],

  

Thanks for taking the time to read my email. I’m a blogger and run the popular travel blog [LINK TO YOUR CONTENT]. I’m currently planning a trip to [LOCATION] on [DATE]. I’m looking to partner with a local hotel during my stay and thought yours would be great.

  

I first found your hotel through [BACKGROUND] and know that my blog readers would love to see [ENTER SPECIFIC ABOUT WHY THIS HOTEL AND HOW IT MATCHES YOUR AUDIENCE’S TASTE.]

  

My blog generates [STATS] readers per month and my Instagram has over [number] or followers and an engagement rate of X%.

  

If you’d like to collaborate together then in return for a complimentary stay, I’d be happy to have a solo review post on my blog as well as feature you in three Instagram photos and regular story updates during my stay.

  

I’d also like to offer you free access to use the content and images within your own marketing material, whether that be for press releases, or public relations content.

  

Look forward to hearing back from you.

[YOUR NAME]

# Pitching for product collaboration
Hi [THEIR NAME],

  

I’ve used [THEIR PRODUCT] religiously for over 3 years and it has helped me [Benefit].

  

I regularly post about your brand on my social channels [LINK] and people often ask questions about [SPECIFIC PRODUCT].

  

In the past, I’ve worked with a brand like X, Y and Z and due to my 100000 followers and 6% engagement rate, we were able to see a 65% increase in sales [ADD YOUR OWN DATA].

  

I would love to work with you on promoting [SPECIFIC PRODUCT] and would love to hear your collaboration ideas.

  

Thanks

[Your Name]

# Pitching a brand to start a relationship
‘Hi [THEIR NAME],

  

I’m just reaching out to say that I absolutely love [brand] and have been using them FOR [TIME FRAME]. One of my favourite product’s is [SPECIFIC PRODUCT] because [REASON].

  

I would love to meet up to hear more about what the brand has in store and potentially see if there are any ways to get involved by promoting your products on my social channels [LINK]

  

Thanks

[YOUR NAME]

# Be prepared to negotiate
By now you should have already worked out how much you should charge for a sponsored post. You should have this in mind when you go ahead and pitch your brands, however also be prepared to negotiate. Consider how much time and effort will need to go into the particular collaboration, what the relationship with the brand is worth, and to what extent your collaboration will help achieve the brands business goals.

      `,
    cover: "/static/mock-images/covers/cover_1.jpg",
    publishedAt: subDays(now, 31).getTime(),
    readTime: "7 min",
    shortDescription:
      "Successful bloggers and influencers know how to pitch themselves. What’s more, understanding the fundamentals of pitching means you are more likely to win collaborations with the brands that you like the most. This helps your overall image to remain authentic and you keep the trust of your hard-earned fans. The reason this is so important is trust.",
    title:
      "How to pitch yourself as an influencer to brands",
  };

  return [200, { post }];
});
