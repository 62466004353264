import { getUserID } from "internal/Checks.js";
import { number } from "prop-types";
import { readGlobalDoc } from "internal/DBFunctions";
import { removehttpsFromLink } from "./Checks";
import { websiteURL } from "./FixedStrings";

export const getStyleOfMainBox = (
  isAuthenticated,
  givenPy = 4,
  BackGroundImage
) => {
  let style = {
    backgroundColor: "background.default",
    minHeight: "100%",
    py: givenPy,
  };
  if (BackGroundImage) {
    style["backgroundImage"] = `url(${BackGroundImage})`;
  }
  if (!isAuthenticated) {
    style["filter"] = "blur(3px)";
    style["pointerEvents"] = "none";
    style["userSelect"] = "none";
    style["overflow"] = "hidden";
    style["height"] = "100%";
  }
  return style;
};

export const AdminUserIDList = ["8X81AgrU6Sc3Z23OMsXw4zLNuno2"];

// export const isAdmin = () => {
//   return AdminUserIDList.includes(getUserID());
// };

export const ExistsAndNotNull = (object, property) => {
  // object null
  if (object == null) {
    return false;
  }
  // has property
  if (Object.prototype.hasOwnProperty.call(object, property)) {
    return false;
  }
  // property not null
  if (object.property) {
    return true;
  }
};

export const StringNotMoreThan = (
  inputString,
  numberCharacters,
  substitution
) => {
  // object null
  if (inputString && inputString.length > numberCharacters) {
    return inputString.substring(0, numberCharacters) + substitution;
  }
  return inputString;
};

export const isAdmin = async (user_id = getUserID()) => {
  var doc_data = await readGlobalDoc("UserInfo");

  console.log(doc_data[user_id] == "admin");
  return doc_data[user_id] == "admin";
};

export const isSupervisor = async (user_id = getUserID()) => {
  var doc_data = await readGlobalDoc("UserInfo");

  return doc_data[user_id] == "supervisor";
};

export const getUserInfo = async (user_id = getUserID()) => {
  var doc_data = await readGlobalDoc("UserInfo");

  if (doc_data[user_id] == null) {
    return "Patient";
  }
  return Capitalize(doc_data[user_id]);
};

const Capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const createUUID = () => {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxxxxxxxxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

// Scrolling
export const scrollTo = (element, to, duration, onDone) => {
  var start = element.scrollTop,
    change = to - start,
    startTime = performance.now(),
    val,
    now,
    elapsed,
    t;

  function animateScroll() {
    now = performance.now();
    elapsed = (now - startTime) / 1000;
    t = elapsed / duration;

    element.scrollTop = start + change * easeInOutQuad(t);

    if (t < 1) window.requestAnimationFrame(animateScroll);
    else onDone && onDone();
  }

  animateScroll();
};

export const easeInOutQuad = (t) => {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
};

// Platformcheck
// https://bestiejs.github.io/platform.js/
export const isInAppBrowser = () => {
  var returnValue = false;
  // console.log(navigator)
  if (
    navigator.userAgent.includes("Instagram") ||
    navigator.userAgent.includes("Facebook") ||
    navigator.userAgent.includes("FB_")
  ) {
    returnValue = true;
  }
  return returnValue;
};

export const getMyPageURL = (UserPageData) => {
  return UserPageData
      ? UserPageData.responseData
        ? removehttpsFromLink(websiteURL) +
          "/u/" +
          UserPageData.responseData.username
        : null
      : null;
};
