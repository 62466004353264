import { getUserID } from "internal/Checks.js";
import { firestore } from "lib/firebase";

// Use Case specific
export const getApplicationsDocRef = () => {
  return firestore.collection("globalData").doc("applications");
};

export const getDealsDocRef = () => {
  return firestore.collection("globalData").doc("deals");
};

export const getFeedDocRef = () => {
  return firestore.collection("globalData").doc("feed");
};

export const getCollaborationsDocRef = () => {
  return firestore.collection("globalData").doc("collaborations");
};

export const getVotingElementsdocRef = () => {
  return firestore.collection("globalData").doc("votingElements");
};

export const getSecuritydocRef = () => {
  return firestore.collection("globalData").doc("securityDoc");
};

export const getUserDataDocRef = () => {
  return getUserDocRef("UserData")
};

export const getUserDocRef = (TableName) => {
  var user_id = getUserID();
  if (user_id == null) return false;

  return firestore
    .collection("userStorage")
    .doc("users")
    .collection(user_id)
    .doc(TableName);
};
