import {
  DefaultNotifications,
  DefaultNotificationsSettings,
} from "./DefaultData";
import {
  analytics_logCrash,
  analytics_logEvent,
  analytics_logSuccess,
  analytics_setUserProperties,
} from "./AnalyticsLib";
import { auth, firebase } from "lib/firebase";
import {
  readDBData,
  updateUserIDArray,
  writeDBData,
} from "internal/DBFunctions";

import { AppVersion } from "./FixedStrings";
import { DefaultUserData } from "internal/DefaultData";
import { EMailVerification } from "./FeatureEnabling";
import { analytics_logUsageConversion } from "internal/AnalyticsLib";
import { getUser } from "./Checks";
import { subscribeUserToGeneral } from "./UserActionFunctions";

export const loginUser = (WithExtendedScope = false) => {
  return new Promise((resolve, reject) => {
    var provider = new firebase.auth.GoogleAuthProvider();

    // todo: Provide option to ignore
    if (WithExtendedScope) {
      provider.addScope(
        "https://www.googleapis.com/auth/fitness.activity.read"
      );
      console.log("added scope");
      provider.addScope("https://www.googleapis.com/auth/fitness.sleep.read");
      provider.addScope("https://www.googleapis.com/auth/fitness.body.read");
      provider.addScope(
        "https://www.googleapis.com/auth/fitness.blood_pressure.read"
      );
    }
    resolve(auth.signInWithPopup(provider));
  });
};

export const afterLogoutActions = () => {
  analytics_logSuccess("Logout");
};

export const afterLoginActions = async (result) => {
  return new Promise(async (resolve, reject) => {
    var user = result.user;

    var email = user.email;
    var displayName = user.displayName;
    var uid = user.uid;
    var photoURL = null;
    var accessToken = null;

    // Will probably override the others above
    // This gives you a Google Access Token. You can use it to access the Google API.
    if (result.additionalUserInfo.providerId == "google.com") {
      photoURL = user.photoURL;
      accessToken = result.credential.accessToken;
    } else if (result.additionalUserInfo.providerId == "facebook.com") {
      email = result.additionalUserInfo.profile.email;
      photoURL = result.additionalUserInfo.profile.picture.data.url;
      accessToken = result.credential.accessToken;
    }

    // Default Data - will be overwritten when existing in db
    var userData = {
      displayName: displayName,
      photoURL: photoURL,
      ...DefaultUserData,
    };

    // Get the token from the window because index is not available here
    // First read the data and then append list
    await readDBData("UserData").then(async (doc_data) => {
      // Has user already logged in? Does his db exist?
      if (doc_data != null) {
        // Overwrite the old login data and fill empty data with the default Values
        userData = Object.assign({}, userData, doc_data);
      } else {
        analytics_logCrash("LoginProblems", {
          userData: userData,
          problem: "User has account, but doesn't has userStorage yet",
        });
      }

      // TODO: MAYBE REMOVE ALL THAT if comeIn is working fine
      // Device Token Handling
      // var deviceToken = window.localStorage.getItem("sentToServer");
      // if (deviceToken != "false" && deviceToken != null) {
      //   // Device Token is set in window
      //   var deviceTokenList = [];

      //   var deviceTokenListUserData = doc_data.deviceTokenList;

      //   if (
      //     deviceTokenListUserData != null &&
      //     deviceTokenListUserData.length != 0
      //   ) {
      //     // Is it already in the server list?
      //     if (!deviceTokenList.includes(deviceToken)) {
      //       deviceTokenList.push(deviceToken);
      //     }
      //   } else {
      //     deviceTokenList.push(deviceToken);
      //   }

      //   // Add the appended list
      //   userData = {
      //     ...userData,
      //     deviceTokenList: deviceTokenList,
      //   };
      // }

      // var notificationsPermission = await Notification.requestPermission();

      // To Update data, will overwrite the existing db UserData
      userData = {
        ...userData,
        accessToken: accessToken,
        email: email,
        uid: uid,
        AppVersion: AppVersion,
        lastLogin: new Date(),
      };

      // Upload data - default or modified
      await writeDBData("UserData", userData);
    });
    analytics_logSuccess("LoginSuccessful", {
      user_uid: uid,
      user_mail: email,
      provider: result.additionalUserInfo.providerId,
      operationType: result.operationType,
    });
    resolve(true);
  });
};

export const afterRegisterActions = async (result) => {
  return new Promise(async (resolve, reject) => {
    var user = result.user;

    var email = user.email;
    var displayName = user.displayName;
    var uid = user.uid;
    var photoURL = null;
    var accessToken = null;

    // Will probably override the others above
    // This gives you a Google Access Token. You can use it to access the Google API.
    if (result.additionalUserInfo.providerId == "google.com") {
      photoURL = user.photoURL;
      accessToken = result.credential.accessToken;
    } else if (result.additionalUserInfo.providerId == "facebook.com") {
      email = result.additionalUserInfo.profile.email;
      photoURL = result.additionalUserInfo.profile.picture.data.url;
      accessToken = result.credential.accessToken;
    }

    // Update array with user functions
    updateUserIDArray(uid);

    // Default Data - will be
    var userData = {
      ...DefaultUserData,
    };

    // // Device Token Handling
    // var deviceToken = window.localStorage.getItem("sentToServer");
    // if (deviceToken != "false" && deviceToken != null) {
    //   // Device Token is set
    //   var deviceTokenList = [deviceToken];

    //   // Add the appended list
    //   userData = {
    //     ...userData,
    //     deviceTokenList: deviceTokenList,
    //   };
    // }

    // var notificationsPermission = await Notification.requestPermission();

    // To Update data, will overwrite the existing db UserData
    userData = {
      ...userData,
      accessToken: accessToken,
      photoURL: photoURL,
      email: email,
      uid: uid,
      displayName: displayName,
      AppVersion: AppVersion,
      lastLogin: new Date(),
    };

    // Add contact and subscribe to all
    subscribeUserToGeneral(userData.email);

    // Upload data - default or modified
    await writeDBData("UserData", userData);

    analytics_logUsageConversion("Registration", {
      user_uid: uid,
      user_mail: email,
      provider: result.additionalUserInfo.providerId,
      operationType: result.operationType,
    });

    if (
      EMailVerification &&
      (result.additionalUserInfo.providerId == null ||
        result.additionalUserInfo.providerId == "password")
    ) {
      var actionCodeSettings = {
        url: "https://creatordash.net/start",
        handleCodeInApp: false,
      };

      await getUser().sendEmailVerification(actionCodeSettings);
    }

    resolve(true);
  });
};

// Triggered every log in
export const comeIn = async () => {
  return new Promise(async (resolve, reject) => {
    // UserData
    await readDBData("UserData").then(async (doc_data) => {
      var userData = {
        ...DefaultUserData,
      };

      // Has user already logged in? Does his db exist?
      if (doc_data != null) {
        userData = Object.assign({}, userData, doc_data);

        // Device Token Handling
        var deviceToken = window.localStorage.getItem("sentToServer");
        if (deviceToken != "false" && deviceToken != null) {
          // Device Token is set in window

          var deviceTokenList = [];

          var deviceTokenListUserData = doc_data.deviceTokenList;

          // Is the deviceToken List in UserData?
          if (
            deviceTokenListUserData != null &&
            deviceTokenListUserData.length != 0
          ) {
            deviceTokenList = deviceTokenListUserData;
            // Is it already in the server list?
            if (!deviceTokenList.includes(deviceToken)) {
              deviceTokenList.push(deviceToken);
            }
          } else {
            deviceTokenList.push(deviceToken);
          }

          // Add the appended list
          userData = {
            ...userData,
            deviceTokenList: deviceTokenList,
          };
        }

        var notificationsPermission = await Notification.requestPermission();

        // To Update data, will overwrite the existing db UserData
        userData = {
          ...userData,
          AppVersion: AppVersion,
          lastLogin: new Date(),
          notificationsPermission: notificationsPermission,
        };

        await writeDBData("UserData", userData);
        analytics_logEvent("ComingBack", { userData: userData });
        analytics_setUserProperties(userData);
      }
    });

    // NotificationsSettings
    await readDBData("NotificationsSettings").then(async (doc_data) => {
      var notificationsSettings = {
        ...DefaultNotificationsSettings,
      };

      // Has user already logged in? Does his db exist?
      if (doc_data != null) {
        notificationsSettings = doc_data;
      }
      await writeDBData("NotificationsSettings", notificationsSettings);
    });

    // Notifications
    await readDBData("Notifications").then(async (doc_data) => {
      var notifications = DefaultNotifications;

      // Has user already logged in? Does his db exist?
      if (doc_data != null) {
        notifications = doc_data;
      }
      await writeDBData("Notifications", notifications);
    });
 
    resolve(true);
  });
};
