import { Box, Button, Container, Paper, Typography } from "@material-ui/core";
import { BlogNewsletter } from "components/blog";
import { analytics } from "lib/firebase";
import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

const PremiumRequired = () => {
  useEffect(() => {
    analytics.logEvent("page_view_PremiumRequired");
  }, []);

  const toLogin = () => {
    analytics.logEvent("to_login");
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        paddingTop: 10,
      }}
    >
      <Container maxWidth="sm">
        <Paper elevation={12}>
          <Box
            sx={{
              display: "flex",
              pb: 2,
              pt: 3,
              px: 3,
            }}
          >
            <Box>
              <Typography color="textPrimary" variant="h5">
                Premium subscription required
              </Typography>
              <Typography color="textSecondary" sx={{ mt: 1 }} variant="body2">
                In order to see this content you need to be logged in and select
                the Premium billing plan. Thank you!
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              px: 3,
              py: 1.5,
            }}
          >
            <Button
              fullWidth
              sx={{ mt: 2 }}
              onClick={toLogin}
              to="/account"
              component={RouterLink}
              color="primary"
              variant="contained"
            >
              Upgrade to Premium
            </Button>
          </Box>
        </Paper>
      </Container>
      <BlogNewsletter />
    </Box>
  );
};

export default PremiumRequired;
