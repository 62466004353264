import { UserTypes } from "./Structs";
import { newNotification } from "./MailFunctions";

const now = new Date();

export const defaultURL = "app.spexdoc.net";

export const CommonCompsData = {
  loginState: false,
  LoginAlertProps: {
    openLoginRequired: false,
    FuncParams: "test",
  },
  PopUpProps: {
    openPopUp: false,
    message: "Test Message",
    type: "info", //info, success, warning, danger, primary
    FuncParams: "test",
  },
  Progress: {
    openProgress: false,
  },
};

export const DefaultUserData = {
  userName: "",
  firstName: "",
  lastName: "",
  plz: "",
  city: "",
  address: "",
  birthDate: "",
  aboutMe: "",
  country: "",
  phone: "",
  state: "",
  dealAlert: false,
  membership: "Basic",
  userType: UserTypes.Unknown,
};

export const ExampleUserData = {
  userName: "",
  firstName: "",
  lastName: "",
  plz: "",
  city: "",
  address: "",
  birthDate: "",
  aboutMe: "",
  country: "",
  phone: "",
  state: "",
  uid: "B8PcqcDJShfyWvPjFZv52D1uJRs2",
  dealAlert: false,
  membership: "Basic",
};

export const DefaultNotifications = () => {
  var defaultNotifications = [];

  var defaultNotification = newNotification(
    "Welcome to the CreatorDash family!",
    "You can find your new notifications here",
    "feed_interaction"
  );

  defaultNotifications.push(defaultNotification);
  return defaultNotifications;
};

export const DefaultNotificationsSettings = {
  announcements: true,
  inAppNotifications: true,
  tipsNewsletter: true,
  brandDealInteractions: true,
  newBrandDeals: true,
  communityActions: true,
};

export const DefaultMedRecord = {
  files: [],
  date: "tbd", // getcurrrentdate
  open: false,
};

export const DefaultApplicationRequestData = {
  motivation: "",
  businessMail: "",
  TikTok: "",
  Instagram: "",
  YouTube: "",
  Blog: "",
  Facebook: "",
};

export const EmptyDeal = {
  id: "1",
  logoFile: "",
  brandName: "",
  author: {
    name: "",
  },
  businessMail: "",
  caption: "",
  description: "",
  likes: 1,
  reach: "",
  type: "",
  category: "",
  image: "",
  rating: 0,
  title: "",
  cpm: 0,
  conditions: "",
  platform: [],
  status: "",
  // followerMin: 5000,
  // followerMax: 100000,
  uid: "",
  updatedAt: "",
  reviews: [],
  files: [],
  collaborators: [],
};
