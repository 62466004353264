import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  TextField,
} from "@material-ui/core";
import { BlogNewsletter } from "components/blog";
import { letMeKnow } from "internal/UserActionFunctions";
import { analytics } from "lib/firebase";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const SoonAvailable = (props) => {
  const { title, ...other } = props;

  const [mailAddress, setmailAddress] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  // useEffect(() => {
  //   analytics.logEvent("page_view_SoonAvailable");
  // }, []);

  const handleLetMeKnow = () => {
    analytics.logEvent("let_me_know");

    letMeKnow(mailAddress, title);

    enqueueSnackbar("We will let you know!", {
      anchorOrigin: {
        horizontal: "center",
        vertical: "top",
      },
      variant: "info",
      autoHideDuration: 5000,
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        paddingTop: 10,
      }}
    >
      <Container maxWidth="sm">
        <Paper elevation={12}>
          <Box
            sx={{
              display: "flex",
              pb: 2,
              pt: 3,
              px: 3,
            }}
          >
            <Box>
              <Typography color="textPrimary" variant="h5">
                Soon available
              </Typography>
              <Typography color="textSecondary" sx={{ mt: 1 }} variant="body2">
                You discovered a soonly available feature. Thank you!
              </Typography>
              <Typography color="textSecondary" sx={{ mt: 1 }} variant="body2">
                We will let you know when the feature is ready.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              px: 3,
              py: 1.5,
            }}
          >
            <TextField
              fullWidth
              label="Email address"
              name="email"
              type="email"
              variant="outlined"
              onChange={() => setmailAddress(event.target.value)}
              value={mailAddress}
            />
            <Button
              color="primary"
              size="large"
              variant="contained"
              sx={{ mt: 2 }}
              onClick={handleLetMeKnow}
            >
              Let Me Know
            </Button>
          </Box>
        </Paper>
      </Container>
      <BlogNewsletter />
    </Box>
  );
};

SoonAvailable.propTypes = {
  title: PropTypes.string,
};

export default SoonAvailable;
