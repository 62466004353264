export const UserTypes = {
  Brand: "Brand",
  Influencer: "Influencer",
  Unknown: "Unknown",
};

export const UserTypeList = [
  UserTypes.Unknown,
   UserTypes.Brand,
   UserTypes.Influencer,
];
