import FeedBack from "components/feedback/Feedback";
import { analytics } from "lib/firebase";
import { useEffect, useState } from "react";
import useSettings from "hooks/useSettings";
import { ThemeProvider, Hidden } from "@material-ui/core";

const getValues = (settings) => ({
  compact: settings.compact,
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  roundedCorners: settings.roundedCorners,
  theme: settings.theme,
});

const FeedBackBox = () => {
  return (
    <>
      <Hidden mdDown>
        <FeedBack
          style={{
            zIndex: "2",
            marginLeft: "20px",
            marginRight: "20px",
            position: "fixed",
          }}
          position="left"
          numberOfStars={5}
          headerText="Feedback?"
          bodyText="If you're having any trouble, found a bug, or have feedback, please write it here:"
          buttonText="Feedback?"
          handleClose={() => console.log("")}
          handleSubmit={(data) => {
            // console.log(data);
            // fetch('xxxxxx', {
            // 	headers: {
            // 		Accept: 'application/json',
            // 		'Content-Type': 'application/json'
            // 	},
            // 	method: 'POST', // or 'PUT'
            // 	body: JSON.stringify(data),
            // }).then((response) => {
            // 	if (!response.ok) {
            // 		return Promise.reject('Our servers are having issues! We couldn\'t send your feedback!');
            // 	}
            // 	response.json()
            // }).then(() => {
            // 	alert('Success!');
            // }).catch((error) => {
            // 	alert('Our servers are having issues! We couldn\'t send your feedback!', error);
            // })
          }}
          handleButtonClick={() => console.log("")}
        />
      </Hidden>
    </>
  );
};

export default FeedBackBox;
