import {
    Box,
    Button,
    Container,
    Paper,
    Typography
} from "@material-ui/core";
import { BlogNewsletter } from "components/blog";
import { analytics } from "lib/firebase";
import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

const LoginRequired = () => {
  useEffect(() => {
    analytics.logEvent("page_view_loginRequired");
  }, []);

  const toRegister = () => {
    analytics.logEvent("toRegister");
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        paddingTop: 10,
      }}
    >
      <Container maxWidth="sm">
        <Paper elevation={12}>
          <Box
            sx={{
              display: "flex",
              pb: 2,
              pt: 3,
              px: 3,
            }}
          >
            <Box>
              <Typography color="textPrimary" variant="h5">
                Registration required
              </Typography>
              <Typography color="textSecondary" sx={{ mt: 1 }} variant="body2">
                In order to see this content you need to be registered. Thank you!
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              px: 3,
              py: 1.5,
            }}
          >
            <Button
              fullWidth
              sx={{ mt: 2 }}
              onClick={toRegister}
              to="/register"
              component={RouterLink}
              color="primary"
              variant="contained"
            >
              Register
            </Button>
          </Box>
        </Paper>
      </Container>
      <BlogNewsletter />
    </Box>
  );
};

export default LoginRequired;
