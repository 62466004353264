export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: "AIzaSyDl94jr5ST-9xlGts3yUpmXZa21zWKMUDk",
  authDomain: "auth.creatordash.net",
  databaseURL: "https://creatordash-a90d0-default-rtdb.firebaseio.com",
  projectId: "creatordash-a90d0",
  storageBucket: "creatordash-a90d0.appspot.com",
  messagingSenderId: "405894709203",
  appId: "1:405894709203:web:ea2130c2134a7d59453926",
  measurementId: "G-6924W6C3NS"
};

export const gtmConfig = {
  containerId: "GTM-WSXXL77" // Not used
};
