import { analytics } from "lib/firebase";
import { analytics_logUseFunctionality } from "./AnalyticsLib";
import { apiURL } from "./FixedStrings";
import { writeLog } from "./DBFunctions";
import { writeRequest } from "internal/DBFunctions";

export const letMeKnow = async (mailAddress, item) => {
  var requestData = { mailAddress: mailAddress, item: item };
  await writeRequest(requestData, "letmeknow");
  return true;
};

export const subscribeUserToGeneral = async (mailAddress) => {
  var listIds = [announcementsListId, tipsNewsletterListId];

  createContact(mailAddress, listIds);

  analytics_logUseFunctionality("UserAddedToAllLists", { mail: mailAddress });
  return true;
};

// To implement yet
export const subscribeUserAsBrand = async (mailAddress) => {
  // var listIds = [announcementsListId, tipsNewsletterListId];

  // createContact(mailAddress, listIds);

  // analytics_logUseFunctionality("UserAddedToAllLists", { mail: mailAddress });
  // return true;
};

// To implement yet
export const subscribeUserAsInfluencer = async (mailAddress) => {
  // var listIds = [announcementsListId, tipsNewsletterListId];

  // createContact(mailAddress, listIds);


  // analytics_logUseFunctionality("UserAddedToAllLists", { mail: mailAddress });
  // return true;
};

export const createContact = async (mailAddress, listIds) => {
  var requestData = { mail: mailAddress, listIds: listIds };

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestData),
  };
  fetch(apiURL + "/api/createContact", requestOptions).then(function (
    response
  ) {
    let result = response.json();
    return result;
  });

  return true;
};

export const addContactToList = async (mailAddress, listId) => {
  var requestData = { mail: mailAddress, listId: listId };

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestData),
  };
  fetch(apiURL + "/api/addContactToList", requestOptions).then(function (
    response
  ) {
    let result = response.json();
    return result;
  });

  return true;
};

export const removeContactFromList = async (mailAddress, listId) => {
  var requestData = { mail: mailAddress, listId: listId };

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestData),
  };
  fetch(apiURL + "/api/removeContactFromList", requestOptions).then(function (
    response
  ) {
    let result = response.json();
    return result;
  });

  analytics_logUseFunctionality("RemoveContactFromList", {
    mail: mailAddress,
    listId: listId,
  });
  return true;
};

export const announcementsListId = 9;
export const tipsNewsletterListId = 10;
