/* eslint-disable react/prop-types */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const defaultButtonStyles = {
  fontSize: "15px",
  background: "#34495e",
  color: "#fff",
  fontWeight: "bold",
  padding: "10px 15px",
  position: "fixed",
  borderRadius: "5px 5px 0 0",
  border: "none",
  bottom: 0,
  backgroundColor: "#34495e",
  width: "auto",
};

class CustomButton extends Component {
  render() {
    const { handleButtonClick, text, styles, position, handleCustomPosition } =
      this.props;
    return (
      <Button
        type="button"
        onClick={handleButtonClick}
        endIcon={<KeyboardArrowUpIcon fontSize="small" />}

        style={handleCustomPosition(position, styles)}
        sx={{
          zIndex: (theme) => theme.zIndex.speedDial,
        }}
      >
        ✌️  Feedback
      </Button>
    );
  }
}

CustomButton.propTypes = {
  handleCustomPosition: PropTypes.func,
  handleButtonClick: PropTypes.func,
  styles: PropTypes.object,
  text: PropTypes.string,
  position: PropTypes.string,
};

CustomButton.defaultProps = {
  position: PropTypes.string,
  styles: defaultButtonStyles,
  text: "Feedback?  ☝️",
  handleButtonClick: () => this.handleButtonClick,
};

export default CustomButton;
