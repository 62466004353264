/* eslint-disable react/prop-types */

import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import {
  Box,
  Button,
  Card,
  Checkbox,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const defaultButtonStyles = {
  position: "absolute",
  fontSize: "20px",
  right: "15px",
  color: "#ffffff",
  background: "none",
  border: 0,
  fontWeight: "bold",
  lineHeight: "40px",
  height: "40px",
};

const defaultHeaderStyles = {
  lineHeight: "40px",
  paddingLeft: "15px",
  height: "40px",
  borderRadius: "6px 6px 0 0",
  backgroundColor: "#34495e",
  color: "#FFFFFF",
  fontSize: "18px",
  fontWeight: "500",
  overflow: "hidden",
};

const Header = ({
  headerText,
  headerBtnText,
  handleClose,
  headerStyles,
  headerBtnStyles,
}) => (
  <Typography
    color="textPrimary"
    variant="h1"
    onClick={handleClose}
    style={headerStyles}
  >
    {headerText}

    <IconButton style={headerBtnStyles}>
      <KeyboardArrowDownIcon fontSize="small" />
    </IconButton>
  </Typography>
);

Header.defaultProps = {
  headerText: "Have Feedback? 📝",
  headerBtnText: "X",
  headerBtnStyles: defaultButtonStyles,
  headerStyles: defaultHeaderStyles,
};

export default Header;
